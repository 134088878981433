.ProductosLoadingPage{
    padding: 0px 0%;
    display: flex;
    flex-wrap: wrap;
   
}
.cardsLoading{
    display: flex;
    overflow: hidden;
    gap: 2rem;
}
.cardProductosLoadingPage{
    width: 35vh;
    height: 15rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    border-radius: 20px;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
}

  @media (max-width: 700px){
    .cardProductosLoadingPage{
        width: 45%;
        height: 12rem;
        margin-right: 1rem;
    }
    .ProductosLoadingPage{
     align-items: center;
     place-content: center;
     justify-content: center;
     padding: 0px 0%;
       
    }
   
    
  }