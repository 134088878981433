

.AnuncioModalContain{
    background-color: #fff;
    padding: 13px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 22rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: fixed;
    z-index: 10000000;
    top: 0;
    right: 0%;
    margin: 1rem 10%;
}
.deFlexbtn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid #c5c2c2;
    padding: 0px 0px 10px 0px;
  
}
.deFlexText{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.deFlexbtn h4{
    color:var(--text-color2);
    font-weight: 400;
    padding-left: 10px;
    font-size: 16px;
}
.deFlexbtn img{
    width: 30px;
    height: 30px;
    object-fit: cover;
}
.btnAnchor{
    background-color: var(--color1);
    padding:12px;
    border: none;
    border-radius: 10px;
    color: var(--blanco);
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
  
}

.closeModalMain{
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: var(--text-color2);
    cursor: pointer;
}

@media (max-width: 500px){
    
.AnuncioModalContain{
    width: auto;
    left: 0%;
    right: 0%;
    margin: 10px 3%;
  
}
.deFlexbtn h4{
   
    font-size: 15px;
}
.deFlexText{
    gap: 0;
}
}