.bgPage {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--blanco);
    background-color: var(--color1);
    margin: 0px 10%;
    border-radius: 10px;
    padding-top: 4rem;
    height: 50vh;
    background-image: url(../../images/bg.png);
    background-repeat: no-repeat;
    background-size: cover;

}
.bgPage a {
    color: var(--blanco);
    text-decoration: none;
    padding: 5px 10px;
  
}


.bgPage .back{
    background-color: transparent;
    color: var(--blanco);
}
@media (max-width: 1024px) {
    .bgPage {
        padding-top: 0;
        height: 30vh;
        margin: 0;
        border-radius: 0;
    }
}