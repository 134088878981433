.titleSectionText{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    background-color: var(--blanco);
    padding: 30px 10%;
    text-align: center;
    margin-top: 2rem;
}
.titleSectionText img{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
}
.titleSectionText h2{
    color: var(--text-color2);
    font-weight: 500;
    font-size: 30px;
}
.titleSectionText span{
    color: var(--text-color2);
    font-weight: 400;
   
}

.titleSectionText a{
    color: var(--blanco);
    font-weight: 500;
    background-color: var(--color1);
    padding: 10px 20px;
    border-radius: 10rem;
    font-size: 16px;
}
.titleSectionText button{
    color: var(--blanco);
    font-weight: 500;
    background-color: var(--color1);
    padding: 5px 20px;
    border-radius: 10rem;
    font-size: 16px;
}
.titleSectionText button span{
    color: var(--blanco);
}
.deFlexBtnTile{
    display: flex;
    align-items: center;
    gap: 10px;
}
@media (max-width: 900px) {
    .titleSectionText img{
        width: 50px;
        height: 50px;
    }
    .titleSectionText h2{
        font-size: 20px;
    }
    .titleSectionText{
        padding: 30px 6%;
    }
    .titleSectionText button span{
        display: flex;
    }
    .deFlexBtnTile{
       flex-direction: column;
    }
    .titleSectionText a{
        font-size: 13px;
    }
    .titleSectionText button{
        padding: 5px 20px;
        font-size: 13px;
    }
    .titleSectionText button span{
        font-size: 13px;
    }
}