.PublicacionesContainHome{
    padding: 50px 9%;
    width: 100%;
    min-height: 60vh;
}

@media (max-width: 900px) {
.PublicacionesContainHome{
    padding: 40px 0%  100px;
  
   flex-direction: column;
   background-color: var(--blanco);
   margin-top: -2rem;
   border-radius: 30px 30px  0 0;
   gap: 10px;
  
}
}