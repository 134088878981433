.ProductsContainPage{
    padding: 50px 10% 100px;
    width: 100%;
    display: flex;
    gap: 2rem;
    min-height: 100vh;
    overflow: hidden;
    
}

.filtrosPage{
    max-width: 17rem;
    min-width: 17rem;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    background-color: var(--blanco);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
    height: 100%;
}
.containFiltros{
    display: flex;
    flex-direction: column;
   
}
.showMoreButton {
    margin: 20px auto;
    display: block;
    padding: 10px 20px;
    background-color: var(--color1);
    color: var(--blanco);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 4rem;
}


.ProductsGrap{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.inputsGrap{
    display: flex;
    flex-direction: column;
    

}
.inputsGrap select{
    background-color: var(--gris);
    border-radius: 10rem;
    padding: 8px;
    border: 0.3px solid #00000037;
}
.range input{
    width: 100%;
}




 .Products{
     display: flex;
     flex-direction: column;
     gap: 2rem;
     width: 100%;
     padding-top: 2rem;
 }

 .ProductsGsrap .cardProdcutmasVendido{
     display: flex;
     flex-direction: column;
     overflow: hidden;
     width: 35vh;
     height: auto;
     border-radius: 10px;
     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
     background-color: var(--blanco);
     position: relative;
 }
 .modelo{
     position: absolute;
     background-color: var(--color1);
     padding: 6px;
     color: var(--blanco);
     border-radius: 2px;
    
 }

 .ProductsGsrap .cardProdcutmasVendido  img{
     width: 100%;
     height: 20vh;
     object-fit: cover;
    
     
 }

 
 .cardText{
     padding: 10px;
     display: flex;
     flex-direction: column;
     gap: 10px;
     justify-content: space-between;
 }
 .cardText h4{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 20ch;
     font-weight: 500;
     font-size: 15px;
     color: var(--text-color2);
 }
 .cardText strong{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 27ch;
    font-weight: 400;
    font-size: 13px;
    color: var(--text-color2);
 }
 .cardText h5{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 20ch;
     font-weight: 500;
     font-size: 16px;
     color: var(--color1);
 }
 .cardText span{
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 27ch;
     font-weight: 400;
     font-size: 14px;
     color: var(--text-color2);
 }
 







input[type="checkbox"] {
    display: none;
}

input[type="checkbox"] + label {
    display: inline-block;
    padding: 5px 15px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s;
    background-color: var(--gris);
    color: var(--text-color2);
    border-radius: 10px;
    width: 100%;
   
}

input[type="checkbox"].activeCheck + label {
    background-color: var(--color1);
    color: var(--blanco);
}

input[type="checkbox"]:checked + label {
    background-color: var(--color1);
    color: var(--blanco);
}
.filterInp label{
   
    display: flex!important;
    justify-content: space-between!important;
 }
.filterInp span{
  
   display: flex!important;
   flex-direction: column!important;
}

.filterInp{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.filterInp legend{
    color: var(--text-color2);
    border-bottom: 1px solid var(--text-color2);
    margin-bottom: 10px;
}
.filterInp label{
    color: var(--text-color2);
    margin-bottom: 10px;
    font-size: 14px;
}
.checkboxFilters div {
    margin-bottom: 10px;
   
}
.ordenarBotones{
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: var(--color1);
    justify-content: space-between;
    gap: 1rem;
}
.ordenarBotones button{
    display: flex;
    width: 100%;
    border: none;
    padding: 10px;
    background-color: var(--gris);
    color: var(--text-color2);
    border-radius: 10px;
    text-align: center;
    justify-content: center;
}
.activeCheck{
    background-color: var(--color1)!important;
    color: var(--blanco)!important;
}
.filterInp button{
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: var(--color1);
    padding: 10px;
    justify-content: center;
}

.no-products-message{
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: center;
    margin-left: 50%;
    padding-top: 4rem;
}

@media (max-width: 900px) {
    .no-products-message{
       
        margin-left: 00%;
        padding-top: 4rem;
        padding: 5%;
    }
    .range {
        width: 100%;
        padding: 0px 4% ;
    }
    .inputsGrap{
        
        display: flex; 
        gap: 10px;
        overflow: scroll;
       
        width: 100%;
        
    }
    .inputsGrap::-webkit-scrollbar{
      background-color: transparent;
     }
     
     .inputsGrap::-webkit-scrollbar-thumb{
        background-color: transparent;
     }
    .inputsGrap select{
       width: 100%;
       padding: 8px  2rem 8px 8px ;
    }
    .ProductsContainPage{
        padding: 0px 2%  100px;
      
       flex-direction: column;
       background-color: var(--blanco);
       margin-top: -2rem;
       border-radius: 30px 30px  0 0;
       gap: 10px;
      
}
    .filtrosPage{
        max-width: 100%;;
        min-width: 100%;;
        background-color:transparent;
        padding: 0;
        border-radius: 10px;
        box-shadow: none;
        height: 100%;
        gap: 10px;
        width: 100%;
        padding-top: 4rem;

    }
    .searchInput{
        padding: 1rem;
    }
    .ProductsGrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 10px;

    }
    .ProductsGsrap .cardProdcutmasVendido{
        margin-bottom: 2rem;
        width: 45%;
      
    }
    .ProductsGsrap .cardProdcutmasVendido img{
      
        width: 100%;
        height: 7rem;
    }
  
.modalFilter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}



.modal-content-filter{
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.btnsFilter {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    background-color: var(--blanco);
    padding: 1rem 2rem;
   
    
}

.closeModalButton{
    background: var(--color1);
    color: var(--blanco);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
}

.filterButton {
    background-color: var(--color1);
    color: var(--blanco);
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    margin: 2%;
    margin-top: 2rem;
}

.checkboxFilters .filterInp {
    margin-bottom: 10px;
}




    
}


