.headerBack {
    display: none;
}

@media (max-width: 900px) {
    .headerBack{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
       
     }
     
     .headerBack a{
         color: var(--blanco);
     }
     .headerBack span{
         color: var(--blanco);
         font-weight: 500
     }
}
