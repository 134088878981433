.detail-contain-public{
    display: flex;
    gap: 2rem;
}
.detailBlog{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.detailBlog p{
    line-height: 1.9rem;
    color: var(--text-color2);
   
}

#detailImg{
    width: 100%;
    height:60vh;
    border-radius: 10px;
    overflow: hidden;
}
#detailImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
   
}
.blogContent{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.cardBlogs{
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
    background-color: var(--blanco);
   
}
.cardBlogs img{
    width: 90px;
    max-height: 129px;
    object-fit: cover;
    border-radius: 10px;
}
.cardProfile{
   width: 100%;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
   padding: 20px;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   gap: 10px;
  
}

.cardProfile{
    color: var(--text-color2);
}
.cardProfile h3{
    font-weight: 600;
    color: var(--text-color2);
}
.cardProfile a{
    color: var(--text-color2);
}
.cardProfile{
    color: var(--text-color2);
}

.cardProfile img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}
@media (max-width: 1024px) {
    .detail-contain-public{
       flex-direction: column;
    }
    #detailImg{
        height:40vh;
    }
    .blogContent{
       padding: 20px;
    }
}