.btnNewProduct{
    position: fixed;
    bottom: 30px;
    right: 0;
    left: 88%;
    background-color: var(--color1);
    color: var(--blanco);
    padding: 25px;
    border-radius: 100%;
   width: 2.5rem;
   height:2.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border: none;
   font-size: 30px;
   z-index: 2;
   cursor: pointer;
   box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
}

.mesageSesion{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    place-content: center;
    height: 100%;
    gap: 1rem;
    text-align: center;
}

.mesageSesion button{
    background-color: var(--color1);
    padding: 10px 20px;
    border-radius: 10px;
    color: var(--blanco);
}
.mesageSesion h2{
    padding: 10px 20px;
    font-weight: 500;
    border-radius: 10px;
    color: var(--text-color2);
    width: 60%;
}

@media (max-width: 700px) {
    .mesageSesion h2{
       font-size: 20px;
        width: 100%;
    }
    .btnNewProduct{
       
        left: 80%;
    }
}